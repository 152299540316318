import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const QueueSelect = ({ selectedQueueId, onChange }) => {
  const [queues, setQueues] = useState("");
  const [selectedQueue, setSelectedQueue] = useState([]);

  useEffect(() => {
    setSelectedQueue(selectedQueueId);
  }, [selectedQueueId]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = (e) => {
    setSelectedQueue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="w-full p-2">
      <label
        htmlFor="queueSelect"
        className="block text-sm font-medium text-gray-700"
      >
        {i18n.t("queueSelect.inputLabel")}
      </label>
      <select
        value={selectedQueue}
        onChange={handleChange}
        className="custom-select w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option key="" value=""></option>
        {Array.isArray(queues) &&
          queues.map((queue, index) => (
            <option key={queue.id} value={queue.id}>
              {queue.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default QueueSelect;
