import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { FormControlLabel, Switch } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Tab, Tabs } from "@material-ui/core";
import SchedulesForm from "../SchedulesForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    outOfHoursMessage: "",
    receptivo: false,
  };

  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);

  const [schedules, setSchedules] = useState([
    {
      weekday: "Segunda-feira",
      weekdayEn: "monday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Terça-feira",
      weekdayEn: "tuesday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Quarta-feira",
      weekdayEn: "wednesday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Quinta-feira",
      weekdayEn: "thursday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Sexta-feira",
      weekdayEn: "friday",
      startTime: "08:00",
      endTime: "18:00",
    },
    {
      weekday: "Sábado",
      weekdayEn: "saturday",
      startTime: "08:00",
      endTime: "12:00",
    },
    {
      weekday: "Domingo",
      weekdayEn: "sunday",
      startTime: "00:00",
      endTime: "00:00",
    },
  ]);

  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const scheduleType = data.find((d) => d.key === "scheduleType");
        if (scheduleType) {
          setSchedulesEnabled(scheduleType.value === "queue");
        }
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
        setSchedules(data.schedules);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "",
        greetingMessage: "",
        receptivo: false,
      });
    };
  }, [queueId, open]);

  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  const handleSaveQueue = async (values) => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, { ...values, schedules });
      } else {
        await api.post("/queue", { ...values, schedules });
      }
      toast.success("Queue saved successfully");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleSaveSchedules = async (values) => {
    toast.success("Clique em salvar para registar as alterações");
    setSchedules(values);
    setTab(0);
  };

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <h1
          className="p-4 text-base font-semibold leading-6 text-gray-900"
          id="form-dialog-title"
        >
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </h1>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setTab(v)}
          aria-label="disabled tabs example"
        >
          <Tab label="Dados da Fila" />
          {schedulesEnabled && <Tab label="Horários de Atendimento" />}
        </Tabs>
        {tab === 0 && (
          <div className="w-full flex flex-wrap mb-4">
            <Formik
              initialValues={queue}
              enableReinitialize={true}
              validationSchema={QueueSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveQueue(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <Form className="w-full">
                  <DialogContent dividers>
                    <div className="w-full p-2">
                      <div className="relative">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nome
                        </label>
                        <Field name="name">
                          {({ field }) => (
                            <input
                              {...field}
                              autoFocus
                              type="text"
                              className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="w-full p-2">
                      <div className="relative">
                        <label
                          htmlFor="color"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cor
                        </label>
                        <Field name="color">
                          {({ field }) => (
                            <div className="flex items-center">
                              <input
                                {...field}
                                required
                                id="color"
                                type="color"
                                className="w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              />
                              <div
                                className="ml-3 h-10 w-10 rounded-md"
                                style={{ backgroundColor: field.value }} // Exibe a cor selecionada em uma div
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="w-full p-2">
                      <div className="relative">
                        <label
                          htmlFor="greetingMessage"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mensagem de boas-vindas
                        </label>
                        <Field name="greetingMessage">
                          {({ field }) => (
                            <textarea
                              {...field}
                              rows={4}
                              id="greetingMessage"
                              type="greetingMessage"
                              className="w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="w-full p-2">
                      <div className="relative">
                        <label
                          htmlFor="outOfHoursMessage"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mensagem de fora de atendimento
                        </label>
                        <Field name="outOfHoursMessage">
                          {({ field }) => (
                            <textarea
                              {...field}
                              rows={4}
                              id="outOfHoursMessage"
                              type="outOfHoursMessage"
                              className="w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="w-full p-2 sm:w-1/2 md:w-1/3">
                      <div className="relative">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          É um departamento receptivo?
                        </label>
                        <Field name="receptivo" type="checkbox">
                          {({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={field.value}
                                  onChange={(event) => {
                                    field.onChange({
                                      target: {
                                        name: "receptivo",
                                        value: event.target.checked,
                                      },
                                    });
                                  }}
                                />
                              }
                              label="Receptivo?"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <button
                      onClick={handleClose}
                      type="button"
                      className="block rounded-md bg-red-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {queueId
                        ? `${i18n.t("queueModal.buttons.okEdit")}`
                        : `${i18n.t("queueModal.buttons.okAdd")}`}
                    </button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {tab === 1 && (
          <div className="w-full p-12">
            <SchedulesForm
              loading={false}
              onSubmit={handleSaveSchedules}
              initialValues={schedules}
              labelSaveButton="Adicionar"
            />
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default QueueModal;
